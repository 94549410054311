import { Edit, SimpleForm, TextInput } from "react-admin";

export const PostEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput label="Title" source="title" />
      <TextInput label="Body" source="body" multiline />
      <TextInput label="Image URL" source="imageUrl" />
    </SimpleForm>
  </Edit>
);
