import { Outlet } from "react-router-dom";
// @ts-ignore
import { i18n } from "shared-i18n";

import "./Header.scss";
import appleSvg from "./assets/images/download-apple.svg";

export default function Header() {
  return (
    <>
      <header className="header navbar-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="nav-inner">
                <nav className="navbar navbar-expand-lg">
                  <a className="navbar-brand" href="/">
                    <h2>
                      {i18n.t("marketingComparisonFeatureSetMMAFantasyLabel")}
                    </h2>
                  </a>
                  <button
                    className="navbar-toggler mobile-menu-btn"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label={i18n.t("marketingHeaderMenuToggle")}
                  >
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse sub-menu-bar"
                    id="navbarSupportedContent"
                  >
                    <ul id="nav" className="navbar-nav ms-auto">
                      <li className="nav-item">
                        <a
                          href="/"
                          className="page-scroll active"
                          aria-label={i18n.t("marketingHeaderHomeAria")}
                        >
                          {i18n.t("marketingHeaderHome")}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="/terms"
                          aria-label={i18n.t("marketingHeaderTermsAria")}
                        >
                          {i18n.t("marketingHeaderTerms")}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="/help"
                          aria-label={i18n.t("marketingHeaderContactAria")}
                        >
                          {i18n.t("marketingHeaderContact")}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="/blog"
                          aria-label={i18n.t("marketingHeaderBlogAria")}
                        >
                          {i18n.t("marketingBlog")}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="button add-list-button">
                    <a
                      target="_blank"
                      href="https://apps.apple.com/ca/app/mma-fantasy/id6445804757"
                      rel="noreferrer"
                    >
                      <img src={appleSvg} alt={i18n.t("marketingAppleAlt")} />
                    </a>

                    <a
                      href="https://play.google.com/store/apps/details?id=com.jetset15.MMAFantasy&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt={i18n.t("marketingGoogleAlt")}
                        width="150"
                        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                      />
                    </a>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Outlet />
    </>
  );
}
