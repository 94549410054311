import { Create, SimpleForm, TextInput } from "react-admin";

export const PostCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput label="Title" source="title" />
      <TextInput label="Body" source="body" multiline />
      <TextInput label="Prompt for GPT" source="systemPrompt" multiline />
      <TextInput label="Image URL" source="imageUrl" />
    </SimpleForm>
  </Create>
);
