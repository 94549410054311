import styles from "./Comparison.module.scss";
import Header from "./Header";
import appleSvg from "./assets/images/download-apple.svg";
// @ts-ignore
import { i18n } from "shared-i18n";

export default function Comparison() {
  return (
    <>
      <Header />
      <div className={`container ${styles.comparison}`}>
        <h1 className="mb-5">{i18n.t("marketingComparisonTitle")}</h1>

        <h4>{i18n.t("marketingComparisonFeatureSetHeading")}</h4>
        <p>
          <strong>
            {i18n.t("marketingComparisonFeatureSetMMAFantasyLabel")}
          </strong>
          :{i18n.t("marketingComparisonFeatureSetMMAFantasyDesc")}
        </p>
        <br />
        <p>
          <strong>{i18n.t("marketingComparisonFeatureSetVerdictLabel")}</strong>
          :{i18n.t("marketingComparisonFeatureSetVerdictDesc")}
        </p>
        <br />

        <h4>{i18n.t("marketingComparisonUxHeading")}</h4>
        <p>
          <strong>
            {i18n.t("marketingComparisonFeatureSetMMAFantasyLabel")}
          </strong>
          :{i18n.t("marketingComparisonUxMMAFantasyDesc")}
        </p>
        <br />
        <p>
          <strong>{i18n.t("marketingComparisonFeatureSetVerdictLabel")}</strong>
          :{i18n.t("marketingComparisonUxVerdictDesc")}
        </p>
        <br />

        <h4>{i18n.t("marketingComparisonCommunityHeading")}</h4>
        <p>
          <strong>
            {i18n.t("marketingComparisonFeatureSetMMAFantasyLabel")}
          </strong>
          :{i18n.t("marketingComparisonCommunityMMAFantasyDesc")}
        </p>
        <br />
        <p>
          <strong>{i18n.t("marketingComparisonFeatureSetVerdictLabel")}</strong>
          :{i18n.t("marketingComparisonCommunityVerdictDesc")}
        </p>
        <br />

        <h4>{i18n.t("marketingComparisonSupportHeading")}</h4>
        <p>
          <strong>
            {i18n.t("marketingComparisonFeatureSetMMAFantasyLabel")}
          </strong>
          :{i18n.t("marketingComparisonSupportMMAFantasyDesc")}
        </p>
        <br />
        <p>
          <strong>{i18n.t("marketingComparisonFeatureSetVerdictLabel")}</strong>
          :{i18n.t("marketingComparisonSupportVerdictDesc")}
        </p>
        <br />

        <h4>{i18n.t("marketingComparisonDownloadHeading")}</h4>
        <p>{i18n.t("marketingComparisonDownloadDesc")}</p>
        <br />

        <a
          target="_blank"
          href="https://apps.apple.com/ca/app/mma-fantasy/id6445804757"
          rel="noreferrer"
        >
          <img src={appleSvg} alt={i18n.t("marketingAppleAlt")} />
        </a>

        <a
          href="https://play.google.com/store/apps/details?id=com.jetset15.MMAFantasy&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt={i18n.t("marketingGoogleAlt")}
            width="150"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          />
        </a>
      </div>
    </>
  );
}
