import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { fakeAuthProvider } from "./auth";

import Home from "./Home";
import Terms from "./Terms";
import Help from "./Help";
import Blog from "./Blog";
import Comparison from "./Comparison";
import AdminPanel from "./admin/AdminPanel";
import BlogPost from "./BlogPost";

interface AuthContextType {
  user: any;
  signin: (user: string, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = React.useState<any>(null);

  let signin = (newUser: string, callback: VoidFunction) => {
    return fakeAuthProvider.signin(() => {
      setUser(newUser);
      callback();
    });
  };

  let signout = (callback: VoidFunction) => {
    return fakeAuthProvider.signout(() => {
      setUser(null);
      callback();
    });
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default function MyRoutes() {
  return (
    <AuthProvider>
      <Routes>
        <Route>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/help" element={<Help />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogPost />} />
          <Route
            path="/ufc-app-verdict-vs-mma-fantasy"
            element={<Comparison />}
          />
          <Route path="/admin/*" element={<AdminPanel />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}
