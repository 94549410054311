import Header from "./Header";
import appleSvg from "./assets/images/download-apple.svg";
import homescreenHero from "./assets/images/hero-image.png";
// @ts-ignore
import { i18n } from "shared-i18n";

export default function Home() {
  return (
    <>
      <Header />
      <section id="home" className="hero-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-12">
              <div className="hero-content">
                <h1 className="wow fadeInLeft" data-wow-delay=".4s">
                  {i18n.t("marketingHomeOpen")}
                </h1>
                <p className="wow fadeInLeft" data-wow-delay=".6s">
                  {i18n.t("marketingHomeDescription")}
                </p>
                <div className="button wow fadeInLeft" data-wow-delay=".8s">
                  <a
                    target="_blank"
                    href="https://apps.apple.com/ca/app/mma-fantasy/id6445804757"
                    rel="noreferrer"
                  >
                    <img src={appleSvg} alt={i18n.t("marketingAppleAlt")} />
                  </a>

                  <a
                    href="https://play.google.com/store/apps/details?id=com.jetset15.MMAFantasy&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt={i18n.t("marketingGoogleAlt")}
                      width="150"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-12">
              <div
                className="wow hero-image fadeInRight text-center"
                data-wow-delay=".4s"
              >
                <img
                  src={homescreenHero}
                  alt={i18n.t("marketingHomeHeroAlt")}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="features section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h3 className="wow zoomIn" data-wow-delay=".2s">
                  {i18n.t("marketingFeatures")}
                </h3>
                <h2 className="wow fadeInUp" data-wow-delay=".4s">
                  {i18n.t("marketingFeaturesHeading")}
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  {i18n.t("marketingFeaturesDescription")}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Feature 1 */}
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-feature wow fadeInUp" data-wow-delay=".2s">
                <i className="lni lni-cloud-upload"></i>
                <h3>{i18n.t("marketingFeatPoolManagement")}</h3>
                <p>{i18n.t("marketingFeatPoolManagementDesc")}</p>
              </div>
            </div>
            {/* Feature 2 */}
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-feature wow fadeInUp" data-wow-delay=".4s">
                <i className="lni lni-star"></i>
                <h3>{i18n.t("marketingFeatLeaderboards")}</h3>
                <p>{i18n.t("marketingFeatLeaderboardsDesc")}</p>
              </div>
            </div>
            {/* Feature 3 */}
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-feature wow fadeInUp" data-wow-delay=".6s">
                <i className="lni lni-reload"></i>
                <h3>{i18n.t("marketingFeatSocialSharing")}</h3>
                <p>{i18n.t("marketingFeatSocialSharingDesc")}</p>
              </div>
            </div>
            {/* Feature 4 */}
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-feature wow fadeInUp" data-wow-delay=".2s">
                <i className="lni lni-shield"></i>
                <h3>{i18n.t("marketingFeatCommunityPools")}</h3>
                <p>{i18n.t("marketingFeatCommunityPoolsDesc")}</p>
              </div>
            </div>
            {/* Feature 5 */}
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-feature wow fadeInUp" data-wow-delay=".4s">
                <i className="lni lni-cog"></i>
                <h3>{i18n.t("marketingFeatPerformanceInsights")}</h3>
                <p>{i18n.t("marketingFeatPerformanceInsightsDesc")}</p>
              </div>
            </div>
            {/* Feature 6 */}
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-feature wow fadeInUp" data-wow-delay=".6s">
                <i className="lni lni-layers"></i>
                <h3>{i18n.t("marketingFeatGameplayFlexibility")}</h3>
                <p>{i18n.t("marketingFeatGameplayFlexibilityDesc")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
