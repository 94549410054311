import Header from "./Header";
import styles from "./Terms.module.scss";
// @ts-ignore
import { i18n } from "shared-i18n";

export default function Terms() {
  return (
    <>
      <Header />
      <div className={`${styles.terms} container`}>
        <h1 className="mb-5">{i18n.t("marketingTsTitle")}</h1>

        <h4>{i18n.t("marketingTsEffectiveDate")}</h4>

        <p>{i18n.t("marketingTsWelcome")}</p>
        <br />

        <h4>{i18n.t("marketingTsAccountsHeading")}</h4>
        <p>{i18n.t("marketingTsAccountsDesc")}</p>
        <br />

        <h4>{i18n.t("marketingTsIpHeading")}</h4>
        <p>{i18n.t("marketingTsIpDesc")}</p>
        <br />

        <h4>{i18n.t("marketingTsTerminationHeading")}</h4>
        <p>{i18n.t("marketingTsTerminationDesc")}</p>
        <br />

        <h4>{i18n.t("marketingTsIndemnificationHeading")}</h4>
        <p>{i18n.t("marketingTsIndemnificationDesc")}</p>
        <br />

        <h4>{i18n.t("marketingTsLimitationOfLiabilityHeading")}</h4>
        <p>{i18n.t("marketingTsLimitationOfLiabilityDesc")}</p>
        <br />

        <h4>{i18n.t("marketingTsProhibitedConductHeading")}</h4>
        <p>{i18n.t("marketingTsProhibitedConductDesc")}</p>
        <br />

        <h1 className="mt-5" id="privacy-policy">
          {i18n.t("marketingPpTitle")}
        </h1>
        <br />

        <p>{i18n.t("marketingPpIntro")}</p>
        <br />

        <h4>{i18n.t("marketingPpInformationWeCollectHeading")}</h4>
        <p>{i18n.t("marketingPpInformationWeCollectDesc")}</p>
        <br />

        <h4>{i18n.t("marketingPpUseOfInformationHeading")}</h4>
        <p>{i18n.t("marketingPpUseOfInformationDesc")}</p>
        <br />

        <h4>{i18n.t("marketingPpSharingOfInformationHeading")}</h4>
        <p>{i18n.t("marketingPpSharingOfInformationDesc")}</p>
        <br />

        <h4>{i18n.t("marketingPpDataSecurityHeading")}</h4>
        <p>{i18n.t("marketingPpDataSecurityDesc")}</p>
        <br />

        <h4>{i18n.t("marketingPpChangesToPolicyHeading")}</h4>
        <p>{i18n.t("marketingPpChangesToPolicyDesc")}</p>
        <br />

        <h4>{i18n.t("marketingPpContactUsHeading")}</h4>
        <p>
          {i18n.t("marketingPpContactUsDesc")}{" "}
          <a href="mailto:mmafantasylive@gmail.com">mmafantasylive@gmail.com</a>
          .
        </p>
      </div>
    </>
  );
}
