import { useEffect, useState } from "react";
import Header from "./Header";
import "./Blog.scss";
import ReactMarkdown from "react-markdown";
// @ts-ignore
import { i18n } from "shared-i18n";

export default function Blog() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const INDEX_JSON_URL = "https://duwc8vywliv4u.cloudfront.net/index.json";

  function ensureHttps(url: string) {
    if (!url) return url;
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `https://${url}`;
    }
    return url;
  }

  useEffect(() => {
    // 1) Detect the user’s locale
    const userLocales = navigator.languages || [navigator.language];

    // 2) Fetch the index.json (list of posts)
    fetch(INDEX_JSON_URL)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Failed to fetch index.json (status: ${res.status})`);
        }
        return res.json();
      })
      .then(async (data) => {
        // data should be something like: { posts: [ { id, imageUrl, translations: { en: url, fr: url } }, ... ] }
        if (!data.posts) {
          throw new Error('index.json has no "posts" field');
        }

        // 3) For each post, fetch the translation JSON for the user’s locale (or fallback to 'en')
        const fetchedPosts: any = await Promise.all(
          data.posts.map(async (post: any) => {
            // Determine which translation URL to fetch
            const translationUrl =
              post.translations[userLocales[0]] || // best match
              post.translations["en"] || // fallback
              null;

            if (!translationUrl) {
              // If there's truly no translation for the user’s locale nor 'en',
              // we’ll still return basic info and skip fetching a translation
              return {
                ...post,
                title: "(No translation available)",
                body: "",
              };
            }

            // Fetch the translation JSON
            const response = await fetch(ensureHttps(translationUrl));
            if (!response.ok) {
              // If we fail to fetch translation, handle gracefully
              console.warn(
                `Failed to fetch translation for post ${post.id} at ${translationUrl}`
              );
              return {
                ...post,
                title: "(Error loading translation)",
                body: "",
              };
            }
            const translationData = await response.json();

            // Merge translation data (title, body) with the post’s metadata
            return {
              id: post.id,
              imageUrl: translationData.imageUrl || post.imageUrl,
              createdAt: translationData.createdAt || post.createdAt,
              updatedAt: translationData.updatedAt || post.updatedAt,
              title: translationData.title,
              body: translationData.body,
            };
          })
        );

        fetchedPosts.sort((a: any, b: any) => b.id - a.id);

        setPosts(fetchedPosts);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading blog posts...</div>;
  }

  if (error) {
    return <div style={{ color: "red" }}>Error: {error}</div>;
  }

  if (!posts.length) {
    return <div>No posts found.</div>;
  }

  return (
    <>
      <Header />
      <section className="faq section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h3 className="wow zoomIn" data-wow-delay=".2s">
                  {i18n.t("marketingBlog")}
                </h3>
                <h2 className="wow fadeInUp" data-wow-delay=".4s">
                  {i18n.t("marketingBlogLatestPosts")}
                </h2>
              </div>
            </div>
          </div>

          <div className="row">
            {posts.map((post: any) => {
              const shortBody =
                post.body.length > 100
                  ? post.body.slice(0, 100) + "..."
                  : post.body;

              return (
                <div className="col-lg-4 col-md-6 col-12" key={post.id}>
                  <div className="single-blog wow fadeInUp">
                    <div className="blog-img">
                      <a href={`/blog/${post.id}`}>
                        {/* Image container for blurred background & main image */}
                        <div className="image-container">
                          <img
                            src={
                              post.imageUrl || "https://dummyimage.com/370x215"
                            }
                            alt={`Post #${post.id}`}
                            className="blurred-background"
                          />
                          <img
                            src={
                              post.imageUrl || "https://dummyimage.com/370x215"
                            }
                            alt={`Post #${post.id}`}
                            className="foreground-image"
                          />
                        </div>
                      </a>
                    </div>
                    <div className="blog-content">
                      <h4>
                        <a href={`/blog/${post.id}`}>
                          {post.title || `Post #${post.id}`}
                        </a>
                      </h4>
                      <ReactMarkdown>{shortBody}</ReactMarkdown>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}
