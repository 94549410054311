import discord from "./assets/images/discord.png";
import Header from "./Header";
import "./Help.scss";
// @ts-ignore
import { i18n } from "shared-i18n";

export default function Help() {
  return (
    <>
      <Header />
      <section className="faq section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h3 className="wow zoomIn" data-wow-delay=".2s">
                  {i18n.t("marketingHelpFaq")}
                </h3>
                <h2 className="wow fadeInUp" data-wow-delay=".4s">
                  {i18n.t("marketingHelpFaqSubtitle")}
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="accordion" id="accordionExample">
                {/* FAQ 1 */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <span className="title">
                        <span className="serial">
                          {i18n.t("marketingHelpFaqQ1Num")}
                        </span>
                        {i18n.t("marketingHelpFaqQ1Title")}
                      </span>
                      <i className="lni lni-plus"></i>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: i18n.t("marketingHelpFaqQ1Desc", {
                            discordImg: `<img src=${discord} alt="Join our Discord" />`,
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* FAQ 2 */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <span className="title">
                        <span className="serial">
                          {i18n.t("marketingHelpFaqQ2Num")}
                        </span>
                        {i18n.t("marketingHelpFaqQ2Title")}
                      </span>
                      <i className="lni lni-plus"></i>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: i18n.t("marketingHelpFaqQ2Desc"),
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* FAQ 3 */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <span className="title">
                        <span className="serial">
                          {i18n.t("marketingHelpFaqQ3Num")}
                        </span>
                        {i18n.t("marketingHelpFaqQ3Title")}
                      </span>
                      <i className="lni lni-plus"></i>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>{i18n.t("marketingHelpFaqQ3Desc")}</p>
                    </div>
                  </div>
                </div>

                {/* FAQ 4 */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <span className="title">
                        <span className="serial">
                          {i18n.t("marketingHelpFaqQ4Num")}
                        </span>
                        {i18n.t("marketingHelpFaqQ4Title")}
                      </span>
                      <i className="lni lni-plus"></i>
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>{i18n.t("marketingHelpFaqQ4Desc")}</p>
                    </div>
                  </div>
                </div>

                {/* FAQ 5 */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      <span className="title">
                        <span className="serial">
                          {i18n.t("marketingHelpFaqQ5Num")}
                        </span>
                        {i18n.t("marketingHelpFaqQ5Title")}
                      </span>
                      <i className="lni lni-plus"></i>
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>{i18n.t("marketingHelpFaqQ5Desc")}</p>
                    </div>
                  </div>
                </div>

                {/* FAQ 6 */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSix">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      <span className="title">
                        <span className="serial">
                          {i18n.t("marketingHelpFaqQ6Num")}
                        </span>
                        {i18n.t("marketingHelpFaqQ6Title")}
                      </span>
                      <i className="lni lni-plus"></i>
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSix"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>{i18n.t("marketingHelpFaqQ6Desc")}</p>
                    </div>
                  </div>
                </div>

                {/* FAQ 7 */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSeven">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      <span className="title">
                        <span className="serial">
                          {i18n.t("marketingHelpFaqQ7Num")}
                        </span>
                        {i18n.t("marketingHelpFaqQ7Title")}
                      </span>
                      <i className="lni lni-plus"></i>
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSeven"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>{i18n.t("marketingHelpFaqQ7Desc")}</p>
                    </div>
                  </div>
                </div>
                {/* End of accordion items */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
