import { List, Datagrid, TextField, EditButton } from "react-admin";

export const PostTranslationList = () => (
  <List>
    <Datagrid>
      <TextField source="id" />
      <TextField source="postId" />
      <TextField source="locale" />
      <TextField source="title" />
      <EditButton />
    </Datagrid>
  </List>
);
