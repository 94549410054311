import { Edit, SimpleForm, TextInput } from "react-admin";

export const PostTranslationEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput label="Title" source="title" />
      <TextInput label="Body" source="body" multiline />
    </SimpleForm>
  </Edit>
);
